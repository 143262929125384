<template>
  <div
    :class="`c-container-login container bg-${
      $q.dark.isActive ? 'dark' : 'white'
    } row justify-center items-center`"
  >
    <div :class="classContainer">
      <div :class="`logo ${classeLogo}`" />
      <form class="q-gutter-y-sm q-mx-lg" @submit.prevent.stop="validaCampos">
        <q-input
          id="inpCPF"
          ref="cpf"
          v-model="cpf"
          type="tel"
          mask="###.###.###-##"
          unmasked-value
          label="CPF"
          :rules="[
            (val) => !!val || 'Informe seu CPF',
            (val) => val.length === 11 || 'CPF incompleto',
          ]"
          lazy-rules
          maxlength="14"
          autofocus
          filled
          :style="isIOS ? 'font-size: 17px' : ''"
          @focus="fieldRef = 'cpf'"
        />
        <q-input
          id="inpDataNascimento"
          ref="dataNascimento"
          v-model="dataNascimento"
          type="tel"
          mask="##/##/####"
          label="Data de Nascimento"
          placeholder="dd/mm/aaaa"
          :rules="[
            (val) => !!val || 'Informe sua data de nascimento',
            (val) => val.length === 10 || 'Data de nascimento incompleta',
            (val) => validaDataNascimento || 'Data de nascimento inválida',
          ]"
          lazy-rules
          maxlength="10"
          filled
          :style="isIOS ? 'font-size: 17px' : ''"
          @focus="fieldRef = 'dataNascimento'"
        >
          <template v-slot:prepend>
            <q-icon name="event" />
          </template>
        </q-input>
        <div v-if="isTotem" class="q-mb-sm">
          <q-teclado-numerico @onKeyPress="handleKeyboardChange" />
        </div>
        <div class="row q-py-md">
          <q-btn
            id="btnAcessar"
            color="primary"
            class="col"
            style="height: 50px"
            type="submit"
          >
            Acessar
          </q-btn>
        </div>
        <div v-if="!isTotem" class="col q-py-md">
          <div v-if="hasLinkWhastApp">
            <p class="text-center">
              Ainda não tem seu Cartão {{ nomeEmpresa }} ?
            </p>
            <q-btn
              id="btnAcessar"
              class="col full-width text-primary"
              style="height: 50px"
              @click="acessarWhatsApp"
            >
              Faça o seu aqui!
            </q-btn>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import MixinMessages from "@/mixins/mixinMessages";
import MixinValidators from "@/mixins/mixinValidators";
import login from "@/plugins/login";
import { mixinScreen } from "lins-mixins";
import moment from "moment";
import QTecladoNumerico from "@/components/shared/QTecladoNumerico";

export default {
  name: "PgLogin",

  mixins: [MixinMessages, MixinValidators, mixinScreen],

  components: {
    QTecladoNumerico,
  },

  data() {
    return {
      cpf: "",
      dataNascimento: "",
      cpfLoginAutomatico: null,
      dataNascimentoLoginAutomatico: null,
      linkWhatsApp: process.env.VUE_APP_LINK_WHATSAPP,
      fieldRef: "",
    };
  },

  created() {
    this.cpfLoginAutomatico = this.$route.query.cpf;
    this.dataNascimentoLoginAutomatico = this.$route.query.nascimento;

    if (this.isLoginAutomatico) {
      this.$_login(
        String(this.cpfLoginAutomatico).padStart(11, 0),
        this.dataNascimentoLoginAutomatico
      );
    }
  },

  computed: {
    redirect() {
      return this.$route.query.redirect;
    },

    classContainer() {
      let container = `${
        this.hasLinkWhastApp
          ? "login-container-whatsapp"
          : this.isTotem
          ? "login-container-totem"
          : "login-container"
      } layout-padding bg-${
        this.$q.dark.isActive ? "dark" : "white"
      } col-xs-11 col-sm-7 col-md-4 col-lg-4 col-xl-4`;
      return this.mxScreen_IsLowResolution
        ? container
        : `${container} shadow-1`;
    },

    classeLogo() {
      return process.env.VUE_APP_EMPRESA === "gang"
        ? this.$q.dark.isActive
          ? `${process.env.VUE_APP_EMPRESA}-white`
          : process.env.VUE_APP_EMPRESA
        : process.env.VUE_APP_EMPRESA;
    },

    isMobile() {
      return this.$q.platform.is.mobile;
    },

    isTotem() {
      return Number(process.env.VUE_APP_TOTEM);
    },

    isIOS() {
      return this.$q.platform.is.ios;
    },

    nomeEmpresa() {
      return process.env.VUE_APP_DESCRICAO_EMPRESA;
    },

    hasLinkWhastApp() {
      return this.isValidURL(this.linkWhatsApp);
    },

    validaDataNascimento() {
      return moment(this.dataNascimento, "YYYY-MM-DD").isValid();
    },

    isLoginAutomatico() {
      return (
        this.cpfLoginAutomatico &&
        this.isValidDate(this.dataNascimentoLoginAutomatico)
      );
    },
  },

  methods: {
    handleKeyboardChange(value) {
      if (this.fieldRef) {
        const currentValue = this[this.fieldRef];

        if (value === "{enter}") {
          this.validaCampos();
          return;
        }

        if (value === "{bksp}") {
          this[this.fieldRef] = currentValue.slice(0, -1);
        } else {
          this[this.fieldRef] = currentValue + value;

          if (String(this[this.fieldRef]).length === 11) {
            this.$refs.dataNascimento.focus();
          }
        }
      }
    },

    validaCampos() {
      this.$refs.cpf.validate();
      this.$refs.dataNascimento.validate();
      if (!this.$refs.cpf.hasError || !this.$refs.dataNascimento.hasError) {
        this.$_login(
          this.cpf,
          this.dataNascimentoFormatada(this.dataNascimento)
        );
      }
    },

    dataNascimentoFormatada(nascimento) {
      return moment(nascimento, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    isValidDate(nascimento) {
      const dataMoment = moment(nascimento, "YYYY-MM-DD", true);
      return dataMoment.isValid();
    },

    async $_login(cpf, nascimento) {
      this.showLoading({
        message: "Validando Informações. Por favor, aguarde!",
      });
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");
        if (await login.login(cpf, nascimento, token)) {
          this.$router.replace(!this.redirect ? "/" : this.redirect);
        } else {
          this.errorMessage({
            title: "Usuário não autenticado",
            message: "Verifique as suas permissões de acesso!",
          });
        }
      } catch (error) {
        if (this.$refs.cpf) {
          this.$refs.cpf.select();
        }
        this.errorMessage({
          title: "Ops! Algo de errado aconteceu",
          error: error,
        });
      } finally {
        this.hideLoading();
      }
    },

    acessarWhatsApp() {
      window.open(this.linkWhatsApp, "_blank");
    },

    errorMethod() {
      this.errorMessage({
        title: "Ops! Algo de errado aconteceu",
        error:
          "Ocorreu um erro ao carrergar o captcha, por favor, tente novamente mais tarde!",
      });
    },
  },
};
</script>

<style lang="scss">
.c-container-login {
  height: 100vh;

  .c-margin-botton {
    margin-bottom: 20px;
  }

  .container {
    margin-top: 20px;
  }

  .login-container {
    height: 480px;
  }

  .login-container-whatsapp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;
  }

  .login-container-whatsapp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 525px;
    width: 100%;
    max-width: 450px;
  }

  .logo {
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
  }

  .logo.pomp {
    background-image: url("../../assets/logo-pomp-min.png");
  }

  .logo.gang {
    background-image: url("../../assets/logo-gang-min.png");
  }

  .logo.gang-white {
    background-image: url("../../assets/logo-gang-min-white.png");
  }
}

.keyboard-button {
  position: fixed;
  bottom: 100px;
  right: 10px;
}
</style>
